<template>
    <div style="width: 100%;background: #fff;">
        <div v-if="nowIndex === 0">
            <div class="right_header">
                <Row :gutter="16">
                    <Col span="5" style="display: flex;align-items: center">
                        <span style="flex-shrink: 0;">订单号：</span>
                        <Input style="width: 200px;" v-model="params.orderNo"/>
                    </Col>
                    <Col span="8" style="display: flex;align-items: center">
                        <span style="flex-shrink: 0;">订单日期：</span>
                        <Row :gutter="16">
                            <Col span="11">
                                <DatePicker style="width: 130px;" type="date" placeholder="开始时间" :transfer="true"
                                            :options="startOption" v-model="startTime"/>
                            </Col>
                            <Col span="2" style="display: flex;align-items: center">至</Col>
                            <Col span="11">
                                <DatePicker style="width: 130px;" :transfer="true" type="date" placeholder="结束时间"
                                            :options="endOption" v-model="endTime"/>
                            </Col>
                        </Row>
                    </Col>
                    <Col span="4" style="display: flex;align-items: center;">
                        <span style="flex-shrink: 0">订单状态：</span>
                        <Select v-model="params.orderState" :transfer="transfer">
                            <Option value="">全部</Option>
                            <Option v-for="(item,index) in orderStatuss" :key="index" :value="Object.keys(item)[0]">
                                {{ item[Object.keys(item)[0]] }}
                            </Option>
                        </Select>
                    </Col>
                    <Col span="7">
                        <div @click="search()" class="button_style"> 查询</div>
                    </Col>
                </Row>
                <!--        <Row :gutter="16">-->
                <!--          <Col span="5">-->
                <!--            <span>订单号</span>-->
                <!--            <Input v-model="params.orderNo" />-->
                <!--          </Col>-->
                <!--          <Col span="7">-->
                <!--            <span>订单日期</span>-->
                <!--            <Row :gutter="16">-->
                <!--              <Col span="11">-->
                <!--                <DatePicker-->
                <!--                    :transfer="true"-->
                <!--                    ref="startTime"-->
                <!--                  type="date"-->
                <!--                  :options="startOption"-->
                <!--                  v-model="startTime"-->
                <!--                ></DatePicker>-->
                <!--              </Col>-->
                <!--              <Col span="2"-->
                <!--                ><span style="margin-bottom: 0; margin-top: 8px">至</span></Col-->
                <!--              >-->
                <!--              <Col span="11">-->
                <!--                <DatePicker-->
                <!--                    :transfer="true"-->
                <!--                  ref="endTime"-->
                <!--                  type="date"-->
                <!--                  :options="endOption"-->
                <!--                  v-model="endTime"-->
                <!--                ></DatePicker>-->
                <!--              </Col>-->
                <!--            </Row>-->
                <!--          </Col>-->
                <!--          <Col span="4" style="position: relative">-->
                <!--            <span>订单状态</span>-->
                <!--            <Select v-model="params.orderState" :transfer="transfer" transfer-class-name="bug" >-->
                <!--              <Option value="">全部</Option>-->
                <!--              <Option v-for="(item,index) in orderStatuss" :key="index" :value="Object.keys(item)[0]">{{item[Object.keys(item)[0]]}}</Option>-->
                <!--            </Select>-->
                <!--          </Col>-->
                <!--          <Col span="4">-->
                <!--            <span>交易类型</span>-->
                <!--            <Select v-model="params.mode" :transfer="transfer">-->
                <!--              <Option value="">全部</Option>-->
                <!--              <Option v-for="(item,index) in tradeStaus" :key="index" :value="Object.keys(item)[0]">{{item[Object.keys(item)[0]]}}</Option>-->
                <!--            </Select>-->
                <!--          </Col>-->
                <!--          <Col span="4">-->
                <!--            <div-->
                <!--              class="button_style"-->
                <!--              style="position: relative; top: 50%"-->
                <!--              @click="search"-->
                <!--            >-->
                <!--              查询-->
                <!--            </div>-->
                <!--          </Col>-->
                <!--        </Row>-->
            </div>
            <div class="right_real_content" style="margin: 50px 20px">
                <div style="height: 50px;line-height: 50px;font-size: 14px;background-color: #EEF1F6;color: #333;font-weight: bold">
                    <Row>
                        <Col span="7" style="text-align: center;border-right: 1px solid #DFE6EC">商品名称</Col>
                        <Col span="3" style="text-align: center;border-right: 1px solid #DFE6EC">商品价格</Col>
                        <Col span="3" style="text-align: center;border-right: 1px solid #DFE6EC">交易类型</Col>
                        <Col span="3" style="text-align: center;border-right: 1px solid #DFE6EC">订单金额</Col>
                        <Col span="3" style="text-align: center;border-right: 1px solid #DFE6EC">订单状态</Col>
                        <Col span="5" style="text-align: center;border-right: 1px solid #DFE6EC">操作</Col>
                    </Row>
                </div>
                <div v-if="dataList.length === 0">
                    <div
                            style="
              text-align: center;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
                    >
                        暂无数据
                    </div>
                </div>
                <div
                        v-for="(item, index) in dataList"
                        style="
            height: 150px;
            margin-bottom: 20px;
            border: 1px solid rgba(205, 240, 243, 1);
            border-radius: 2px;
          "
                        :key="index"
                >
                    <div
                            style="
              height: 60px;
              background-color: #f3fcfc;
              line-height: 60px;
              font-size: 14px;

            "
                    >
            <span
                    style="display: inline-block; margin-left: 20px"
                    class="order_span"
            >订单号：{{ item.no }}
            </span>
                        <span class="order_span">订单时间：{{ item.createdTime }}</span>
                        <span class="order_span">店铺：{{ item.shopName || "-- --" }}</span>
                    </div>
                    <div
                            style="height: 90px; line-height: 90px; font-size: 14px"
                            id="per_good"
                    >
                        <Row style="height: 100%;">
                            <Col span="7"
                                 style="height: 100%;display: flex;align-items: center;justify-content: center">
                <span
                        class="san_hang_sheng"
                        style="

                    line-height: 20px;
                  "
                >
                  {{ item.goods[0].goodsName }}
                </span>
                            </Col>
                            <Col span="3" style="text-align: center">¥{{ item.amount }}</Col>
                            <Col span="3" style="text-align: center">{{
                                item.goods[0].modeName || "-- --"
                                }}
                            </Col>
                            <Col span="3" style="text-align: center">
                                <div style="height: 20px; line-height: 20px; margin-top: 25px">
                  <span
                  >¥{{
                      item.goods[0].price + item.goods[0].serviceCharge
                    }}</span
                  >
                                </div>
                                <div style="height: 20px; line-height: 20px">
                                    <span>(含服务费¥{{ item.goods[0].serviceCharge }})</span>
                                </div>
                            </Col>
                            <Col span="3" style="text-align: center">
                                <div
                                        style="
                    height: 20px;
                    line-height: 20px;
                    margin-top: 25px;
                    color: #fda736;
                  "
                                >
                                    <span>{{ item.stateName }}</span>
                                </div>
                                <div
                                        style="height: 20px; line-height: 20px; cursor: pointer"
                                        @click="seeDetail(item)"
                                >
                                    <span>订单详情</span>
                                </div>
                            </Col>
                            <Col span="5" style="text-align: center;height: 100%">
                                <div v-if="item.stateName === '已取消'">
                  <span
                          style="color: #0E61DF; cursor: pointer"
                          @click="delOrder(item.id)"
                  >删除订单</span
                  >
                                </div>
                                <div v-if="item.stateName === '未付款'">
                  <span
                          style="color: #0E61DF; cursor: pointer"
                          @click="pay(item)"
                  >去支付</span
                  >
                                    |
                                    <span
                                            style="color: #0E61DF; cursor: pointer"
                                            @click="removeOrder(item)"
                                    >取消订单</span
                                    >
                                </div>
                                <div v-if="item.stateName !== '未付款' && item.stateName !== '已取消'">
                  <span style="color: #0E61DF; cursor: pointer">
                    -- --</span
                  >
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Page
                        :total="total"
                        show-elevator
                        style="
            margin-top: 50px;
            float: right;
            margin-right: 40px;
            margin-bottom: 20px;
          "
                        @on-change="changePage"
                />
            </div>
            <div v-html="payForm"></div>
        </div>

        <!--专利订单详情-->
        <div v-if="nowIndex === 2">
            <div style="height: auto; border: 1px solid #dae2e5">
                <div
                        style="
            height: 70px;
            padding-left: 20px;
            line-height: 70px;
            font-size: 20px;
            background-color: rgb(243, 252, 252);
            color: black;
          "
                >
                    订单详情
                </div>
                <div style="padding-bottom: 30px" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >基本信息</span
          >
                    <div class="div_center" style="border-bottom: none">
                        <Row :gutter="16">
                            <Col span="12">
                                <Row>
                                    <Col span="24">
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单号</span
                      >：{{ detailItem.no }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单日期</span
                      >：{{ detailItem.updatedTime.split(" ")[0] }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单状态</span
                      >：{{ detailItem.stateName }}
                                        </div>
                                        <div
                                                v-if="detailItem.type === '支付宝'"
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：支付宝
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                                v-if="detailItem.type === '微信'"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：微信
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                                v-if="detailItem.type === '线下'"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：线下
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span="12">
                                <Row>
                                    <Col span="24">
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >交易单号</span
                      >：{{ detailItem.tradeNo }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >会员名称</span
                      >：{{ detailItem.buyer }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >企业名称</span
                      >：{{ detailItem.buyerMember }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px;      text-overflow: ellipsis;
                          overflow: hidden;
                          white-space: nowrap;"
                                        >
                      <span
                              :title="detailItem.remark"
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;

                        "
                      >买家留言</span
                      >：{{ detailItem.remark || '无' }}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 300px" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >商品清单</span
          >

                    <Table
                            border
                            :columns="detailColumn"
                            :data="detailData"
                            :disabled-hover="true"
                    >
                    </Table>
                </div>

                <div style="height: auto;padding-bottom: 30px; border-bottom: none" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >发票信息</span
          >
                    <div v-if="!qyOrSelf">
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                发票抬头：<span>{{ detailItem.invoiceHeader }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票邮寄地址：<span>{{ detailItem.invoiceMailingAddress }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                发票收件人姓名：<span>{{ detailItem.invoiceRecipientName }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人联系方式：{{ detailItem.invoiceRecipientPhone }}<span></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="qyOrSelf">
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                企业名称：<span>{{ detailItem.invoiceHeader }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                公司地址：<span>{{ detailItem.companyAddress }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                企业税号：<span>{{ detailItem.taxpayerNumber }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票邮寄地址：{{ detailItem.invoiceMailingAddress }}<span></span>
                            </div>
                        </div>
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                企业开户行：<span>{{ detailItem.businessBank }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人姓名：<span>{{ detailItem.invoiceRecipientName }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                企业银行开行账号：<span>{{ detailItem.businessBankNo }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人联系方式：{{ detailItem.invoiceRecipientPhone }}<span></span>
                            </div>
                        </div>
                        <div style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                            公司电话：<span>{{ detailItem.businessPhone }}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div v-html="payForm"></div>
        <div
                class="center"
                style="background-color: white; padding: 40px 0"
                v-if="nowIndex == 1"
        >
            <div
                    style="
          font-size: 24px;
          width: 100%;
          text-align: center;
          margin-bottom: 45px;
        "
            >
                您的订单已完成支付，请等待后台运营人员审核！
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 15px">
                支付金额：￥{{ priceSum }}
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 15px">
                订单号：{{ orderNo}}
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 30px">
                支付方式：{{ payTypeTxt }}
            </div>
            <div style="width: 410px; margin: 0 auto;">
                <div
                        style="
            border-width: 1px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 255, 255);
            width: 178px;
            height: 38px;
            color: rgb(255, 106, 0);
            cursor: pointer;
            margin: 0 auto;
            text-align: center;
            line-height: 38px;
            margin-top: 30px;
          "
                        @click="seeDetail2()"
                >
                    查看订单
                </div>
                <div
                        @click="openZoosUrl"
                        style="
            border-width: 1px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 106, 0);
            width: 178px;
            height: 38px;
            color: white;
            cursor: pointer;

            text-align: center;
            line-height: 38px;
            margin-top: 30px;
            margin-left: 10px;
          "
                >
                    联系客服
                </div>
            </div>
        </div>

        <Modal
                v-model="deleteOrderModal"
                title="删除专利订单信息"
                width="400"
                :footer-hide="footHide"
        >
            <div>
                <div style="padding: 20px">
                    <div
                            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
                    >
                        <div style="margin-bottom: 30px">
                            <span>您确定删除该订单信息？</span>
                        </div>
                        <div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                                    @click="notDel()"
                            >
                                我再想想
                            </div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                                    @click="confirmDel()"
                            >
                                确定
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
                v-model="removeOrderModal"
                title="取消专利订单信息"
                width="400"
                :footer-hide="footHide"
        >
            <div>
                <div style="padding: 20px">
                    <div
                            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
                    >
                        <div style="margin-bottom: 30px">
                            <span>您确定取消该订单信息？</span>
                        </div>
                        <div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                                    @click="notRemove()"
                            >
                                我再想想
                            </div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                                    @click="confirmRemove()"
                            >
                                确定
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
                v-model="payModal"
                title="微信支付"
                width="400"
                :footer-hide="footHide"
                @on-visible-change="watchPayModal"
        >
            <vue-qr :text="wechatUrl" :margin="0" :size="200"></vue-qr>
        </Modal>

        <Modal
                v-model="lineModal"
                title="上传凭证"
                width="400"
                :footer-hide="footHide"
        >
            <Form :model="lineForm">
                <FormItem label="上传支付凭证：">
                    <Upload
                            action=""
                            :before-upload="(file) => handleUpload(file)"
                            :multiple="footHide"
                    >
                        <Button>选择文件</Button>
                        {{ hasChooseFile }}
                    </Upload>
                </FormItem>
                <FormItem label="备注：">
                    <Input v-model="lineForm.remarks" type="textarea" :autosize="{minRows: 6,maxRows:8}"></Input>
                </FormItem>
            </Form>
            <div>
                <div
                        class="button_style"
                        style="margin-left: 26px; width: 80px; position: relative; top: 50%"
                        @click="notUpload()"
                >
                    我再想想
                </div>
                <div
                        class="button_style"
                        style="
            margin-left: 26px;
            width: 80px;
            position: relative;
            top: 50%;
            background-color: #fda736;
          "
                        @click="confirmUpload()"
                >
                    确定
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    import {
        getPatentOrderList,
        delPatentOrder,
        removePatentOrder,
    } from "../../../plugins/api/EnterpriseManagementCenter";
    import {uploadVoucher} from "../../../plugins/api/EnterpriseManagementCenter";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";
    import {get} from "../../../plugins/http/http";
    import baseUrl from "../../../plugins/http/baseUrl";
    import {uploadImg} from "../../../plugins/api/sciencePolicy";

    export default {
        name: "PatentOrder",
        mounted() {
            if (this.$route.query.payStatus) {
                this.nowIndex = this.$route.query.payStatus;
                this.id = this.$route.query.orderId;
                get(`${baseUrl}/v1/order/detail/${this.id}`).then((res) => {
                    if (res.code == 0) {
                        this.priceSum = this.priceSum = Number(res.result.amount) + Number(res.result.serviceAmount);
                        this.orderId = res.result.no;
                        this.orderNo = res.result.no;
                        this.payTypeTxt = res.result.type;
                    }
                });
                this.$parent.changeHeight();
            } else if (this.$route.query.orderId) {
                this.id = this.$route.query.orderId;
                this.seeDetail2();
            } else {
                this.nowIndex = 0;
                this.params.orderNo = this.$route.params.orderSn || "";
                this.getPatentOrder();
            }
        },
        watch: {
            startTime() {
                let self = this;
                this.endOption = {
                    disabledDate(date) {
                        if (self.startTime) {
                            return date && date.valueOf() < self.startTime;
                        }
                    },
                };
            },
            endTime() {
                let self = this;
                this.startOption = {
                    disabledDate(date) {
                        if (self.endTime) {
                            return date && date.valueOf() > self.endTime;
                        }
                    },
                };
            },
        },
        data() {
            return {
                detailRow: {},
                transfer: true,
                // 订单状态
                orderStatuss: [

                    {EXAMINING: "待审核"},
                    {SIGNING: "待签约"},
                    {RECEIPTING: "待接单"},
                    {HANDLING: "待办理"},
                    {CHANGING: "待变更"},
                    {ARCHIVING: "待归档"},
                    {COMPLETED: "已完成"},
                    {CANCELLED: "已取消"},
                    {REFUNDING: "待退款"},
                    {REFUNDED: "已退款"},
                ],
                tradeStaus: [
                    {TRANSFER: '转让'}, {LICENSE: '许可'}
                ],
                hasChooseFile: "未选择文件",
                lineForm: {
                    remarks: "",
                    paymentVoucher: "",
                    orderSn: "",
                },
                wechatUrl: "",
                id: "",
                priceSum: 0,
                aliModal: false,
                payForm: "",
                payTypeTxt: "",
                lineModal: false,
                payModal: false,
                total: 0,
                params: {
                    pageNum: 1,
                    pageSize: 10,
                    mode: "",
                    orderType: "PATENT",
                    orderNo: "",
                    orderState: "",
                    orderCreateFrom: "",
                    orderCreateTo: "",
                    isFront: true,
                },
                dataList: [],
                transactionType: "",
                orderId: "",
                orderNo: "",
                orderStatus: "",
                footHide: false,
                nowIndex: 0,
                removeOrderModal: false,
                deleteOrderModal: false,
                startTime: "",
                endTime: "",
                startOption: {},
                endOption: {},
                //删除的id
                deleteId: "",
                removeId: "",
                qyOrSelf: true,
                removeItem: {},
                detailItem: {},
                detailData: [],
                detailColumn: [
                    {
                        title: "店铺",
                        key: "shopName",
                        align: "center",
                    },
                    {
                        title: "商品名称",
                        key: "goods",
                        align: "center",
                    },
                    {
                        title: "交易类型",
                        key: "modeName",
                        align: "center",
                    },
                    {
                        title: "商品价格",
                        key: "price",
                        align: "center",
                    },
                    {
                        title: "服务费",
                        key: "serviceCharge",
                        align: "center",
                    },
                    {
                        title: "订单总额",
                        key: "totalPrice",
                        align: "center",
                    },
                ],
            };
        },
        methods: {
            openZoosUrl() {
                openZoosUrl('chatwin');
            },

            // 取消第三方的模态框
            notUpload() {
                this.lineModal = false;
            },

            // 确定上传
            async confirmUpload() {
                let json = await uploadVoucher(this.lineForm);
                if (json.code == 0) {
                    this.lineForm.paymentVoucher = "";
                    this.lineForm.orderSn = "";
                    this.lineForm.remarks = ""
                    successToast("上传成功，等待客服确认");
                    this.lineModal = false;
                    this.getPatentOrder();
                } else {
                    errorToast(json.message);
                }
            },

            // 上传图片的接口
            async handleUpload(file) {
                let that = this;
                const FileExt = file.name.replace(/.+\./, ""); //取得文件的后缀名
                if (FileExt == "png" || FileExt == "jpg") {
                    const reader = new FileReader();
                    //将文件读取为 DataURL 以data:开头的字符串
                    let code = "";
                    let _that = this;
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                        code = e.target.result;

                        let data = {
                            imageBase64: code,
                            dataType: 0,
                        };
                        uploadImg(data).then((res) => {
                            if (res.code == "200") {
                                successToast("上传成功");
                                that.lineForm.paymentVoucher = res.data;
                                this.hasChooseFile = "已选择";
                            } else {
                                errorToast(res.message);
                            }
                        });
                    };
                } else {
                    warnToast("只支持jpg和png");
                    this.file = "";
                }

                // if(json.code == 200){

                // }
            },


            // 支付
            pay(row) {
                this.lineForm.orderSn = row.no;
                this.id = row.id;
                let orderId = row.id;
                this.orderNo = row.no;
                this.priceSum = row.goods[0].price + row.goods[0].serviceCharge;
                get(`${baseUrl}/v1/pay/${orderId}`, {
                    notifyUrl: "",
                    returnUrl:
                        location.href + `&payStatus=1&orderId=${orderId}&payType=${row.type}`,
                }).then((res) => {
                    if (res.code === 0) {
                        //this.shop = res.result.shop;
                        if (row.type === "ALIPAY") {
                            this.payTypeTxt = "支付宝支付";
                            this.payForm = res.result.form;
                            setTimeout(() => {
                                document.forms[0].submit();
                            });
                        }
                        if (row.type === "LINE") {
                            this.payTypeTxt = "线下支付";
                            this.lineModal = true;
                        }
                        if (row.type === "WECHAT") {
                            this.payTypeTxt = "微信支付";
                            this.wechatUrl = res.result.params.code_url;
                            this.payModal = true;
                            this.loopCheckOrder(orderId);
                        }
                    } else {
                        this.$Message.info(res.message);
                    }
                });
            },
            checkPayStatus(id) {
                get(`${baseUrl}/v1/order/detail/${id}`).then((res) => {
                    if (res.code === 0) {
                        if (res.result.state === "EXAMINING") {
                            clearInterval(this.checkOrderTimer);
                            this.nowIndex = 1;
                            this.payModal = false;
                            this.orderId = res.result.id;
                            this.priceSum = Number(res.result.amount) + Number(res.result.serviceAmount);
                        }
                        this.orderId = res.result.id;
                        this.priceSum = Number(res.result.amount) + Number(res.result.serviceAmount);
                    }
                });
            },
            loopCheckOrder(id) {
                this.checkOrderTimer = setInterval(() => {
                    this.checkPayStatus(id);
                }, 5000);
            },
            qrcode(url) {
                new QRCode("wxQrcode", {
                    width: 500,
                    height: 500,
                    text: url,
                    colorDark: "#000",
                    colorLight: "#fff",
                });
            },


            // 查询
            async search() {
                if (this.startTime) {
                    this.params.orderCreateFrom = this.startTime.getTime();
                }
                if (this.endTime) {
                    this.params.orderCreateTo = this.endTime.getTime();
                }
                this.params.pageNum = 1;
                const json = await getPatentOrderList(this.params);
                if (json.code === 0) {
                    this.dataList = json.result.list;
                    this.total = json.result.total;
                    this.$parent.changeHeight();
                    this.params.orderCreateTo = "";
                    this.params.orderCreateFrom = ""
                } else {
                    this.dataList = [];
                }
            },
            // 翻页
            async changePage(index) {
                this.params.pageNum = index;
                const json = await getPatentOrderList(this.params);
                this.dataList = json.result.list;
                this.total = json.result.total;
                this.$parent.changeHeight();
            },

            // 订单列表
            async getPatentOrder() {
                const json = await getPatentOrderList(this.params);

                this.dataList = json.result.list;
                this.total = json.result.total;

                this.$parent.changeHeight();
            },

            // 完成订单查看详情的回调
            seeDetail2() {
                get(`${baseUrl}/v1/order/detail/${this.id}`).then((res) => {
                    let item = res.result;
                    this.detailItem = res.result;
                    if (this.detailItem.businessBank || this.detailItem.businessBank == "") {
                        this.qyOrSelf = true;
                    } else {
                        this.qyOrSelf = false;
                    }
                    let detailData = {};
                    detailData.shopName = item.shopName;
                    detailData.goods = item.goods[0].goodsName;
                    detailData.modeName = item.goods[0].modeName;
                    detailData.price = "¥" + item.goods[0].price;
                    detailData.serviceCharge = "¥" + item.goods[0].serviceCharge;
                    detailData.totalPrice =
                        "¥" + (Number(item.goods[0].price) + Number(item.goods[0].serviceCharge));
                    this.detailData.push(detailData);
                    // this.$router.push({
                    //   path: "/enterprise/enterprise-management-center",
                    //   query: {
                    //     id: this.$route.query.id,
                    //   },
                    // });
                    this.nowIndex = 2;
                    this.$parent.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                });
            },


            //查看详情；
            seeDetail(item) {
                this.detailRow = item;
                scrollTo(0, 0);
                get(`${baseUrl}/v1/order/detail/${item.id}`).then((res) => {
                    let item = res.result;

                    this.detailItem = item;
                    if (this.detailItem.businessBank || this.detailItem.businessBank == "") {
                        this.qyOrSelf = true;
                    } else {
                        this.qyOrSelf = false;
                    }
                    let detailData = {};
                    detailData.shopName = item.shopName;
                    detailData.goods = item.goods[0].goodsName;
                    detailData.modeName = item.goods[0].modeName;
                    detailData.price = "¥" + item.goods[0].price;
                    detailData.serviceCharge = "¥" + item.goods[0].serviceCharge;
                    detailData.totalPrice =
                        "¥" + (Number(item.goods[0].price) + Number(item.goods[0].serviceCharge));
                    this.detailData.push(detailData);
                    scrollTo(0, 0);
                    this.nowIndex = 2;
                    this.$parent.changeHeight();
                    scrollTo(0, 0);
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                });
            },

            watchPayModal(status) {
                if (status == false) {
                    clearInterval(this.checkOrderTimer);
                }
            },


            //取消订单
            removeOrder(item) {
                this.removeItem = item;
                this.removeId = item.id;
                this.removeOrderModal = true;
            },
            notRemove() {
                this.removeOrderModal = false;
            },
            async confirmRemove() {
                let params = {
                    id: this.removeId,
                };
                const json = await removePatentOrder(params);

                if (json.code === 0) {
                    successToast("取消成功");
                    this.getPatentOrder();
                } else {
                    errorToast(json.message);
                }
                this.removeOrderModal = false;
                this.$parent.changeHeight();
            },

            // 删除订单
            delOrder(id) {
                this.deleteId = id;
                this.deleteOrderModal = true;
            },
            notDel() {
                this.deleteOrderModal = false;
            },
            async confirmDel() {
                let params = {
                    id: this.deleteId,
                };
                const json = await delPatentOrder(params);
                if (json.code === 0) {
                    successToast("删除成功");
                    this.getPatentOrder();
                } else {
                    errorToast(code.message);
                }
                this.deleteOrderModal = false;
                this.$parent.changeHeight();
            },
        },
    };
</script>

<style scoped lang="scss">
    .wxQrcodePay {
        img {
            width: 200px !important;
            height: 200px !important;
        }
    }

    /deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
    .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
        line-height: 32px !important;
    }

    /deep/ .ivu-modal-footer {
        display: none;
    }

    /deep/ .ivu-modal-header-inner {
        text-align: center;
        background-color: rgb(245, 246, 250) !important;
    }

    /deep/ .ivu-modal-header {
        background-color: rgb(245, 246, 250) !important;
    }

    .san_hang_sheng {
        padding: 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-align: left !important;
    }

    .button_style {
        cursor: pointer;
        display: inline-block;
        background-color: #0E61DF;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    /deep/ #per_good .ivu-col {
        border-right: 1px solid rgb(205, 240, 243) !important;
    }

    /deep/ #per_good .ivu-row .ivu-col:last-child {
        border-right: none !important;
    }

    .order_span {
        margin-right: 40px;
    }

    .right_header {
        padding: 20px;
        margin-bottom: 40px !important;
        border-bottom: 1px solid #e8eaec;
        overflow: hidden;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .bug {
        position: relative;
    }

    /deep/ .ivu-input, .ivu-select {
        height: 30px !important;
        line-height: 30px !important;
        background: #FFFFFF;
        border-radius: 4px !important;
    }

    .button_style {
        display: inline-block;
        background-color: #0E61DF;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }
</style>
